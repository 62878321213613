import { Group, Button, createStyles, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { deleteDoc, doc, setDoc } from "firebase/firestore";
import React from "react";
import { CheckmarkIcon } from "react-hot-toast";
import { EditCircle, TrashX } from "tabler-icons-react";
import { db } from "../utilities/firebase";
import toast from "react-hot-toast";
import { useIonRouter } from "@ionic/react";

const useStyles = createStyles((theme) => {
  const BREAKPOINT = theme.fn.smallerThan("sm");

  return {
    control: {
      [BREAKPOINT]: {
        flex: 1,
      },
    },
  };
});

export default function ItemManagePanel({ itemId }: { itemId: string }) {
  const { classes } = useStyles();
  const router = useIonRouter();

  // Handle item delete
  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, "items", itemId));
      toast.success("Successfully deleted item.");
      router.push("/sell");
    } catch (e) {
      toast.error("Failed to delete item.");
    }
  };

  const openDeleteModal = () =>
    openConfirmModal({
      title: "Please confirm your action",
      children: (
        <Text size="sm">
          Are you sure you want to delete this item? This action cannot be
          undone.
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancelled delete"),
      onConfirm: handleDelete,
    });

  const handleMarkAsSold = async () => {
    try {
      const docRef = doc(db, "items", itemId);
      await setDoc(
        docRef,
        {
          status: "markedAsSold",
        },
        { merge: true }
      );

      toast.success("Successfully marked item as sold.");
    } catch (e) {
      toast.error("Failed to mark item as sold.");
    }
  };
  const openMarkAsSoldModal = () =>
    openConfirmModal({
      title: "Please confirm your action",
      children: (
        <Text size="sm">
          Are you sure you want to mark this item as sold? This action cannot be
          undone.
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancelled mark as sold"),
      onConfirm: handleMarkAsSold,
    });

  return (
    <Group position="right" mt="md">
      <Button
        color="green"
        className={classes.control}
        leftIcon={<CheckmarkIcon />}
        onClick={openMarkAsSoldModal}
      >
        Mark as Sold
      </Button>
      {/* <Button
        color="blue"
        className={classes.control}
        leftIcon={<EditCircle />}
      >
        Edit Item
      </Button> */}
      <Button
        color="red"
        className={classes.control}
        leftIcon={<TrashX />}
        onClick={openDeleteModal}
      >
        Delete Item
      </Button>
    </Group>
  );
}
