import React, { useState, useRef } from "react";
import {
  IonButtons,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonPage,
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import { useMantineColorScheme } from "@mantine/core";

import SellForm from "./SellForm";
import RequestForm from "./RequestForm";

const PostModal = ({
  onDismiss,
}: {
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
}) => {
  const { colorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";

  const [selectedForm, setSelectedForm] = useState<"sell" | "request">("sell");

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="medium" onClick={() => onDismiss(null, "cancel")}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>
            {selectedForm === "sell" ? "Sell an Item" : "Request an Item"}
          </IonTitle>
        </IonToolbar>

        <IonToolbar>
          <IonSegment
            value={selectedForm}
            onIonChange={(e) => {
              setSelectedForm(e.detail.value as "sell" | "request");
            }}
          >
            <IonSegmentButton value="sell">
              <IonLabel>Sell an Item</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="request">
              <IonLabel>Post a Request</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>

      {selectedForm === "sell" ? (
        <SellForm onDismiss={onDismiss} />
      ) : (
        <RequestForm onDismiss={onDismiss} />
      )}
    </IonPage>
  );
};

export default PostModal;
