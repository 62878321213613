import {
	Paper,
	Text,
	TextInput,
	Textarea,
	Button,
	Group,
	SimpleGrid,
	createStyles,
	Alert,
	Stack,
	CheckIcon,
} from "@mantine/core";
import bg from "../assets/bg.svg";
import ImageGallery from "./ImageGallery";
import { Title } from "@mantine/core";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../utilities/firebase";
import { IconAlertCircle } from "@tabler/icons";
import { EditCircle, TrashX } from "tabler-icons-react";
import { CheckmarkIcon } from "react-hot-toast";
import ItemManagePanel from "./ItemManagePanel";

const useStyles = createStyles((theme) => {
	const BREAKPOINT = theme.fn.smallerThan("sm");

	return {
		wrapper: {
			display: "flex",
			borderRadius: theme.radius.lg,
			padding: 4,

			[BREAKPOINT]: {
				flexDirection: "column",
			},
		},

		form: {
			boxSizing: "border-box",
			flex: 1,
			padding: theme.spacing.xl,
			paddingLeft: theme.spacing.xl * 2,
			borderLeft: 0,

			[BREAKPOINT]: {
				padding: theme.spacing.md,
				paddingLeft: theme.spacing.md,
			},
		},

		fields: {
			marginTop: -12,
		},

		fieldInput: {
			flex: 1,

			"& + &": {
				marginLeft: theme.spacing.md,

				[BREAKPOINT]: {
					marginLeft: 0,
					marginTop: theme.spacing.md,
				},
			},
		},

		fieldsGroup: {
			display: "flex",

			[BREAKPOINT]: {
				flexDirection: "column",
			},
		},

		contacts: {
			boxSizing: "border-box",
			position: "relative",
			borderRadius: theme.radius.lg - 2,
			// backgroundImage: `url(${bg})`,
			backgroundColor: theme.colors.dark[8],
			backgroundSize: "cover",
			backgroundPosition: "center",
			padding: theme.spacing.xl,
			flex: "0 0 360px",

			[BREAKPOINT]: {
				marginBottom: theme.spacing.sm,
				paddingLeft: theme.spacing.md,
			},
		},
	};
});

export function ItemDetails({
	type,
	id,
	title,
	categories,
	images,
	userUid,
	price,
	description,
	contactInformation,
	presentSignInModal,
}: {
	type: string;
	id: string;
	title: string;
	categories: string[];
	images: string[];
	userUid: string;
	price: number;
	description: string;
	contactInformation: string;
	presentSignInModal: Function;
}) {
	const { classes } = useStyles();
	const [user, loading] = useAuthState(firebaseAuth);

	const isCurrentUsersItem = user?.uid === userUid;

	return (
		<div className={classes.wrapper}>
			<div className={classes.form}>
				<Title>{title}</Title>

				<div className={classes.fields}>
					<Text weight={700} style={{ fontSize: 30 }}>
						${price}
					</Text>

					<Text>{description}</Text>

					<ImageGallery imageUrls={images} />

					{isCurrentUsersItem && type === "item" && (
						<ItemManagePanel itemId={id} />
					)}
				</div>
			</div>

			<div className={classes.contacts}>
				<Stack justify="space-between" sx={{ height: "100%" }}>
					<Stack>
						<Text size="lg" weight={700} sx={{ color: "#fff" }}>
							Contact Information
						</Text>

						<Alert
							icon={<IconAlertCircle size={16} />}
							title="Verified"
							color="green"
						>
							This seller is a verified member of the Yale community. You can
							contact them directly.
						</Alert>

						{!user && !loading && (
							<Alert
								variant="outline"
								icon={<IconAlertCircle size={16} />}
								title="Restricted Information"
								color="red"
							>
								You must be signed in using your @yale.edu email to view contact
								information.
								<Button
									onClick={() => {
										presentSignInModal();
									}}
								>
									Sign in or sign up
								</Button>
							</Alert>
						)}

						{user && (
							<Text size="lg" weight={500} sx={{ color: "#fff" }}>
								{contactInformation}
							</Text>
						)}
					</Stack>
					{type === "item" && (
						<Button disabled>Reserve Item (coming soon)</Button>
					)}
				</Stack>
			</div>
		</div>
	);
}
