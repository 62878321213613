import { Redirect, Route } from "react-router-dom";
import {
	IonApp,
	IonIcon,
	IonLabel,
	IonRouterOutlet,
	IonTabBar,
	IonTabButton,
	IonTabs,
	setupIonicReact,
	useIonModal,
} from "@ionic/react";
import { Toaster } from "react-hot-toast";
import { IonReactRouter } from "@ionic/react-router";
import {
	cashOutline,
	ellipse,
	home,
	homeOutline,
	peopleCircleOutline,
	person,
	square,
	triangle,
} from "ionicons/icons";
import {
	MantineProvider,
	ColorSchemeProvider,
	ColorScheme,
} from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";

import Tab1 from "./pages/Tab1";
import Tab2 from "./pages/Tab2";
import Tab3 from "./pages/Tab3";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import GetStarted from "./pages/GetStarted";
import { useEffect, useState } from "react";
import SignInModal from "./components/modals/SignInModal";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "./utilities/firebase";
import ItemPage from "./pages/ItemPage";
import SearchPage from "./pages/SearchPage";
import { sendEmailVerification } from "firebase/auth";
import RequestPage from "./pages/RequestPage";
import ReferPage from "./pages/ReferPage";

setupIonicReact({ mode: "ios" });

const App: React.FC = () => {
	const [colorScheme, setColorScheme] = useState<ColorScheme>("light");
	const toggleColorScheme = (value?: ColorScheme) => {
		setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
		document.body.classList.toggle("dark");
	};

	const [signInModalOpen, setSignInModalOpen] = useState(false);
	const presentSignInModal = () => setSignInModalOpen(true);

	const [user] = useAuthState(firebaseAuth);

	useEffect(() => {
		// If user and user's email does not end in @yale.edu then sign out and alert
		if (user && !user.email?.endsWith("@yale.edu")) {
			alert("You must sign in with a Yale email address.");
			firebaseAuth.signOut();

			setSignInModalOpen(true);
		} else if (user && !user.emailVerified) {
			// Send verification email
			sendEmailVerification(user);
			alert(
				"You must verify your email address before using market @ yale. Please check your email."
			);
			firebaseAuth.signOut();
		} else if (user) {
			setSignInModalOpen(false);
		}
	}, [user]);

	const AuthenticatedRouter = () => (
		<IonTabs>
			<IonRouterOutlet>
				<Route exact path="/home">
					<Tab1 presentSignInModal={presentSignInModal} />
				</Route>
				<Route exact path="/sell">
					<Tab2 presentSignInModal={presentSignInModal} />
				</Route>
				<Route path="/profile">
					<Tab3 presentSignInModal={presentSignInModal} />
				</Route>

				<Route exact path="/items/:id">
					<ItemPage presentSignInModal={presentSignInModal} />
				</Route>

				<Route exact path="/requests/:id">
					<RequestPage presentSignInModal={presentSignInModal} />
				</Route>

				<Route exact path="/search">
					<SearchPage />
				</Route>

				<Route exact path="/flyer">
					<ReferPage presentSignInModal={presentSignInModal} />
				</Route>

				<Route path="/" render={() => <Redirect to="/home" />} exact={true} />

				<Route path="/" render={() => <Redirect to="/home" />} exact={true} />
			</IonRouterOutlet>
			<IonTabBar slot="bottom">
				<IonTabButton tab="home" href="/home">
					<IonIcon icon={home} />
					<IonLabel>Home</IonLabel>
				</IonTabButton>
				<IonTabButton tab="sell" href="/sell">
					<IonIcon icon={cashOutline} />
					<IonLabel>Sell</IonLabel>
				</IonTabButton>

				<IonTabButton tab="profile" href="/profile">
					<IonIcon icon={person} />
					<IonLabel>Profile</IonLabel>
				</IonTabButton>
			</IonTabBar>
		</IonTabs>
	);

	return (
		<ColorSchemeProvider
			colorScheme={colorScheme}
			toggleColorScheme={toggleColorScheme}
		>
			<MantineProvider
				theme={{
					headings: {
						fontFamily: "Lexend",
					},
					fontFamily: "Lexend",
				}}
			>
				<ModalsProvider>
					<div>
						<Toaster />
					</div>

					<IonApp>
						<SignInModal
							opened={signInModalOpen}
							setOpened={setSignInModalOpen}
						/>
						<IonReactRouter>
							{/* {signedIn ? <AuthenticatedRouter /> : <NonAuthenticatedRouter />} */}
							<AuthenticatedRouter />
						</IonReactRouter>
					</IonApp>
				</ModalsProvider>
			</MantineProvider>
		</ColorSchemeProvider>
	);
};

setupIonicReact();

export default App;

const NonAuthenticatedRouter = () => (
	<IonRouterOutlet>
		<Route exact path="/get-started">
			<GetStarted />
		</Route>
		<Route exact path="/">
			<Redirect to="/get-started" />
		</Route>
	</IonRouterOutlet>
);
