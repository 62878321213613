import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useLocation, useParams } from "react-router";
import { collection, doc, query, where } from "firebase/firestore";
import { db } from "../utilities/firebase";
import { ItemDetails } from "../components/ItemDetails";
import {
  StoredItem,
  transformUploadcareGroupUrl,
} from "../components/ItemsForYou";
import {
  Container,
  Group,
  Button,
  Skeleton,
  Loader,
  Title,
} from "@mantine/core";
import ToggleDarkMode from "../components/ToggleDarkMode";
import { Text } from "@mantine/core";
import { ItemCard } from "../components/ItemCard";
import ItemDisplayWithSearch from "../components/ItemDisplayWithSearch";

// Individual item page for /items/:id
export default function CategoryPage() {
  const location = useLocation();

  // Get the category name from the query string
  const { category } = Object.fromEntries(new URLSearchParams(location.search));

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{category || "Find Item"}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{category || "Find Item"}</IonTitle>
          </IonToolbar>
          {/* <IonToolbar>
            <IonSearchbar></IonSearchbar>
          </IonToolbar> */}
        </IonHeader>
        <Container size="xl" style={{ width: "100%", height: "100%" }}>
          <ItemDisplayWithSearch defaultCategory={category} />
        </Container>
      </IonContent>
    </IonPage>
  );
}
