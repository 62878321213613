import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import "./Tab3.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../utilities/firebase";
import { Button, Container, Stack, Text, Title } from "@mantine/core";
import { signOut } from "firebase/auth";
import DormSelector from "../components/DormSelector";

const Tab3 = ({ presentSignInModal }: { presentSignInModal: Function }) => {
	const [user, loading, error] = useAuthState(firebaseAuth);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Profile</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonHeader collapse="condense">
					<IonToolbar>
						<IonTitle size="large">Profile</IonTitle>
					</IonToolbar>
				</IonHeader>

				<Container size="xl" style={{ width: "100%" }}>
					{" "}
					{loading ? (
						<p>Loading...</p>
					) : user ? (
						<Stack>
							<p>Logged in as {user.email}</p>

							<DormSelector />
							<Button onClick={() => signOut(firebaseAuth)}>Sign Out</Button>
						</Stack>
					) : (
						<Stack mt="md">
							<Title>
								Join market@yale to get access to exclusive deals and items from
								the Yale community.
							</Title>

							<Text>
								Sign up or sign in with your Yale email address to get started.
							</Text>
							<Button
								size="md"
								onClick={() => {
									presentSignInModal();
								}}
							>
								Sign in/up with Yale
							</Button>
						</Stack>
					)}
				</Container>
			</IonContent>
		</IonPage>
	);
};

export default Tab3;
