import {
  Box,
  Container,
  Group,
  Title,
  Stack,
  Text,
  SimpleGrid,
} from "@mantine/core";
import React from "react";
import { motion } from "framer-motion";
import { categories } from "../config/categories";
import { useIonRouter } from "@ionic/react";

export default function ExploreItems() {
  return (
    <Container size="xl" style={{ width: "100%" }}>
      <Title mb={6}>Explore</Title>

      <Categories />
    </Container>
  );
}

function Categories() {
  function CategoryCard({
    name,
    color,
    emoji,
  }: {
    name: string;
    color: { radialGradient: string; color: string };
    emoji: string;
  }) {
    const router = useIonRouter();
    return (
      <motion.button
        onClick={() => router.push(`/search?category=${name}`)}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.95 }}
        style={{
          // Add shadow
          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
          // Add rounded corners
          borderRadius: "5px",
          maxWidth: "100px",
          minWidth: "100px",
          minHeight: "90px",
          maxHeight: "90px",

          backgroundImage: color.radialGradient,
        }}
      >
        <Stack spacing={2}>
          {emoji}
          <Text weight="bold" size="sm" color="white">
            {name}
          </Text>
        </Stack>
      </motion.button>
    );
  }

  return (
    <Group spacing={12} style={{ width: "100%" }}>
      {categories.map((category) => (
        <CategoryCard
          name={category.name}
          color={category.color}
          emoji={category.emoji}
        />
      ))}
    </Group>
  );
}
