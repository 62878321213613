import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useParams } from "react-router";
import { doc } from "firebase/firestore";
import { db } from "../utilities/firebase";
import { ItemDetails } from "../components/ItemDetails";
import { transformUploadcareGroupUrl } from "../components/ItemsForYou";
import { Container, Group, Button, Skeleton, Loader } from "@mantine/core";
import ToggleDarkMode from "../components/ToggleDarkMode";
import { Text } from "@mantine/core";

// Individual item page for /items/:id
export default function ItemPage({
  presentSignInModal,
}: {
  presentSignInModal: Function;
}) {
  // Get item id from url
  const { id } = useParams<{ id: string }>();

  // Fetch the item from Firebase
  const [value, loading, error, snapshot] = useDocumentData(
    doc(db, "items", id)
  );

  const router = useIonRouter();

  const TitleWithLoader = () => (
    <>{loading ? <Skeleton width={200} height={30} /> : value?.name}</>
  );

  return (
    <>
      <IonPage>
        <IonHeader collapse="fade">
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
            <Group>
              <IonTitle>
                <TitleWithLoader />
              </IonTitle>
              <ToggleDarkMode />
            </Group>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen={true}>
          <Container size="xl" style={{ width: "100%", height: "100%" }}>
            {loading ? (
              <Loader />
            ) : (
              <ItemDetails
                type="item"
                id={id}
                title={value?.title}
                categories={value?.categories}
                price={value?.price}
                userUid={value?.userUid}
                description={value?.description}
                images={transformUploadcareGroupUrl(value?.imageGroupUrl)}
                contactInformation={value?.contactInformation}
                presentSignInModal={presentSignInModal}
              />
            )}
          </Container>
        </IonContent>
      </IonPage>
    </>
  );
}
