import React from "react";
import { Container, Group, SimpleGrid, Skeleton, Title } from "@mantine/core";
import { ItemCard } from "./ItemCard";
import {
  useCollection,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import { collection, query, limit } from "firebase/firestore";
import { db } from "../utilities/firebase";

export interface StoredItem {
  categories: string[];
  contactInformation: string;
  description: string;
  imageGroupUrl: string;
  price: number;
  title: string;
  uid?: string;
  userUid: string;
}

export interface StoredRequest {
  categories: string[];
  contactInformation: string;
  description: string;
  price: number;
  title: string;
  uid?: string;
  userUid: string;
}

// Helper function to transform an Uploadcare Group URL to an array of image URLs

// The count of urls in the group is the number after ~ at the end of the URL
// We can access the an individual image by appending /nth/IMAGE_INDEX to the end of the URL
export const transformUploadcareGroupUrl = (groupUrl: string) => {
  if (!groupUrl) return [];

  const count = parseInt(groupUrl.split("~")[1]);
  const urls = [];
  for (let i = 0; i < count; i++) {
    urls.push(groupUrl + "nth/" + i + "/");
  }
  return urls;
};

export default function ItemsForYou() {
  const [value, loading, error] = useCollection(
    query(collection(db, "items"), limit(30))
  );

  const formattedValues = value?.docs?.map((doc) => {
    const docData = doc.data() as StoredItem;

    return {
      category: docData.categories[0],
      description: docData.description,
      image: transformUploadcareGroupUrl(docData.imageGroupUrl)[0],
      link: `/items/${doc.id}`,
      price: `$${docData.price}`,
      title: docData.title,
    };
  });

  return (
    <Container size="xl" style={{ width: "100%" }}>
      <Title mb={6}>For You</Title>

      <SimpleGrid
        spacing={12}
        cols={2}
        breakpoints={[
          { minWidth: "sm", cols: 2 },
          { minWidth: "md", cols: 3 },
          { minWidth: 1200, cols: 4 },
        ]}
      >
        {loading && <Skeleton height={300} width={300} />}
        {formattedValues?.map((item) => (
          <ItemCard {...item} />
        ))}
      </SimpleGrid>
    </Container>
  );
}
