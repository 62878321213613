import { IonFab, IonFabButton, IonIcon, useIonModal } from "@ionic/react";
import { add } from "ionicons/icons";
import React, { useState } from "react";
import { OverlayEventDetail } from "@ionic/core/components";
import PostModal from "./modals/PostModal";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../utilities/firebase";

export default function FloatingPostButton({
	presentSignInModal,
}: {
	presentSignInModal: Function;
}) {
	const [present, dismiss] = useIonModal(PostModal, {
		onDismiss: (data: string, role: string) => dismiss(data, role),
	});

	const [message, setMessage] = useState(
		"This modal example uses the modalController to present and dismiss modals."
	);
	const [user, loading, error] = useAuthState(firebaseAuth);

	function openModal() {
		if (!user) {
			presentSignInModal();
			return;
		}

		present({
			onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
				if (ev.detail.role === "confirm") {
					setMessage(`Hello, ${ev.detail.data}!`);
				}
			},
		});
	}

	return (
		<IonFab vertical="bottom" horizontal="end" slot="fixed">
			<IonFabButton onClick={openModal} color="secondary">
				<IonIcon icon={add} />
			</IonFabButton>
		</IonFab>
	);
}
