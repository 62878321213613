import {
	IonContent,
	IonHeader,
	IonNavLink,
	IonPage,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import "./Tab3.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../utilities/firebase";
import { Button, Container, Stack, Text, Title } from "@mantine/core";
import { signOut } from "firebase/auth";
import { IconExternalLink } from "@tabler/icons";

const ReferPage = ({
	presentSignInModal,
}: {
	presentSignInModal: Function;
}) => {
	const [user, loading, error] = useAuthState(firebaseAuth);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Refer</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonHeader collapse="condense">
					<IonToolbar></IonToolbar>
				</IonHeader>

				<Container size="md">
					<Stack>
						<Title>🧋 Boba/Amazon on us!</Title>
						<Title order={3}>
							All you have to do is list an item you’re willing to sell on
							Market and fill out this Google Form!
						</Title>

						{!user && (
							<Button
								size="xl"
								color="green"
								onClick={() => presentSignInModal()}
							>
								Register for Market
							</Button>
						)}

						{/*TODO: <Button
							size="xl"
							component="a"
							href="https://forms.gle/UDj3yPLwNcEXgrGi9"
							variant="outline"
							color="green"
							leftIcon={<IconExternalLink size={14} />}
						>
							Click to fill out form
						</Button> */}

						<Text size="sm">
							Terms & Conditions: Items listed must belong to you, be authentic,
							and be listed in good faith. You can’t just post a picture of
							anything, it has to be something that you’ll actually be willing
							to sell. We reserve the right to terminate this program at any
							time and investigate and potentially disqualify fraudulent
							listings.
						</Text>
					</Stack>
				</Container>
			</IonContent>
		</IonPage>
	);
};

export default ReferPage;
