import { useToggle, upperFirst } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import {
	TextInput,
	PasswordInput,
	Text,
	Paper,
	Group,
	PaperProps,
	Button,
	Divider,
	Checkbox,
	Anchor,
	Stack,
	ButtonProps,
} from "@mantine/core";
import { BrandGoogle } from "tabler-icons-react";
import {
	useCreateUserWithEmailAndPassword,
	useSignInWithEmailAndPassword,
	useSignInWithGoogle,
} from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../utilities/firebase";

function GoogleButton(props: ButtonProps) {
	const [signInWithGoogle, user, loading, error] =
		useSignInWithGoogle(firebaseAuth);
	return (
		<>
			<Button
				loading={loading}
				onClick={() => signInWithGoogle()}
				leftIcon={<BrandGoogle />}
				variant="default"
				color="gray"
				{...props}
			/>
			{error && <Text>{error.message}</Text>}
		</>
	);
}

export function AuthenticationCard(props: PaperProps) {
	const [type, toggle] = useToggle(["login", "register"]);

	const [
		createUserWithEmailAndPassword,
		signUpUser,
		signUpLoading,
		signUpError,
	] = useCreateUserWithEmailAndPassword(firebaseAuth);

	const [signInWithEmailAndPassword, loginUser, loginLoading, loginError] =
		useSignInWithEmailAndPassword(firebaseAuth);

	const form = useForm({
		initialValues: {
			email: "",
			name: "",
			password: "",
			terms: true,
		},

		validate: {
			email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
			password: (val) =>
				val.length <= 6
					? "Password should include at least 6 characters"
					: null,
		},
	});

	return (
		<Paper radius="md" p="xl" withBorder {...props}>
			<Text size="lg" weight={500}>
				Welcome to market
			</Text>

			<Text size="md" weight={300}>
				You must use your @yale.edu email address to {type}
			</Text>

			<Group grow mb="md" mt="md">
				<GoogleButton radius="xl">Google</GoogleButton>
				{/*
        <TwitterButton radius="xl">Twitter</TwitterButton> */}
			</Group>

			<Divider label="Or continue with email" labelPosition="center" my="lg" />

			<form
				onSubmit={form.onSubmit(() => {
					// If email doesn't end with @yale.edu alert and don't submit
					if (!form.values.email.endsWith("@yale.edu")) {
						alert("You must use your @yale.edu email address");
						return;
					}

					if (type === "login") {
						signInWithEmailAndPassword(form.values.email, form.values.password);
					} else {
						createUserWithEmailAndPassword(
							form.values.email,
							form.values.password
						);
					}
				})}
			>
				<Stack>
					{type === "register" && (
						<TextInput
							label="Name"
							placeholder="Your name"
							value={form.values.name}
							onChange={(event) =>
								form.setFieldValue("name", event.currentTarget.value)
							}
						/>
					)}

					<TextInput
						required
						label="Email"
						placeholder="hello@market.app"
						value={form.values.email}
						onChange={(event) =>
							form.setFieldValue("email", event.currentTarget.value)
						}
						error={form.errors.email && "Invalid email"}
					/>

					<PasswordInput
						required
						label="Password"
						placeholder="Your password"
						value={form.values.password}
						onChange={(event) =>
							form.setFieldValue("password", event.currentTarget.value)
						}
						error={
							form.errors.password &&
							"Password should include at least 6 characters"
						}
					/>

					{type === "register" && (
						<Checkbox
							label="I accept terms and conditions"
							checked={form.values.terms}
							onChange={(event) =>
								form.setFieldValue("terms", event.currentTarget.checked)
							}
						/>
					)}
				</Stack>

				<Group position="apart" mt="xl">
					<Anchor
						component="button"
						type="button"
						color="dimmed"
						onClick={() => toggle()}
						size="xs"
					>
						{type === "register"
							? "Already have an account? Login"
							: "Don't have an account? Register"}
					</Anchor>
					<Button type="submit" loading={signUpLoading || loginLoading}>
						{upperFirst(type)}
					</Button>

					<Text>{signUpError?.message || loginError?.message || " "}</Text>
				</Group>
			</form>
		</Paper>
	);
}
