import React from "react";

import { AuthenticationCard } from "../Authentication/AuthenticationCard";
import { Modal } from "@mantine/core";

export default function AuthenticationModal({
  opened,
  setOpened,
}: {
  opened: boolean;
  setOpened: Function;
}) {
  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title="Sign in required"
    >
      <AuthenticationCard />
    </Modal>
  );
}
