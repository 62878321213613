import { useConnector } from "react-instantsearch-hooks-web";
import connectRange from "instantsearch.js/es/connectors/range/connectRange";
import { Box, Button, Popover, RangeSlider, Text } from "@mantine/core";

import type {
	RangeConnectorParams,
	RangeWidgetDescription,
} from "instantsearch.js/es/connectors/range/connectRange";
import { InputLabel } from "@mantine/core/lib/Input/InputLabel/InputLabel";

export type UseRangeSliderProps = RangeConnectorParams;

export function useRangeSlider(props?: UseRangeSliderProps) {
	return useConnector<RangeConnectorParams, RangeWidgetDescription>(
		connectRange,
		props
	);
}

export default function SearchRangeSlider(props: UseRangeSliderProps) {
	const { start, range, canRefine, refine, sendEvent } = useRangeSlider(props);

	return (
		<>
			<Popover width="80%" position="bottom" withArrow shadow="md">
				<Popover.Target>
					<Button variant="outline" style={{ borderRadius: 50 }} color="blue">
						Filter by Price
					</Button>
				</Popover.Target>
				<Popover.Dropdown>
					<Box style={{ padding: "24px" }}>
						<RangeSlider
							size="xl"
							labelAlwaysOn
							minRange={1}
							label={(value) => `$${value}`}
							min={range.min}
							max={range.max}
							// value={[start[0] as number, start[1] as number]}
							onChange={(value) => {
								sendEvent("click", value as unknown as string);
								refine(value);
							}}
							disabled={!canRefine}
						/>
						<Text size="sm">Adjust the slider to filter by price</Text>
					</Box>
				</Popover.Dropdown>
			</Popover>
		</>
	);
}
