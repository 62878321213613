import { useMantineColorScheme } from "@mantine/core";
import React from "react";
import s from "./PageGradient.module.css";

export default function PageGradient() {
  const { colorScheme } = useMantineColorScheme();
  const isDarkMode = colorScheme === "dark";

  return (
    <div className={s.gradient_background} style={{ zIndex: -1 }}>
      <div
        className={isDarkMode ? s.background_fade : s.light_background_fade}
      ></div>
    </div>
  );
}
