import {
	IonHeader,
	IonToolbar,
	IonButtons,
	IonButton,
	IonTitle,
	IonContent,
} from "@ionic/react";
import {
	TextInput,
	Textarea,
	Text,
	MultiSelect,
	Input,
	NumberInput,
	Stack,
} from "@mantine/core";
import { addDoc, collection } from "firebase/firestore";
import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import toast from "react-hot-toast";
import { Loader, CurrencyDollar } from "tabler-icons-react";
import { categories } from "../../../config/categories";
import { firebaseAuth, db } from "../../../utilities/firebase";
import { StoredItem, StoredRequest } from "../../ItemsForYou";
import UploadImages from "../../UploadImages";

export default function RequestForm({
	onDismiss,
}: {
	onDismiss: (data?: string | null | undefined | number, role?: string) => void;
}) {
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [price, setPrice] = useState<number>();
	const [imageGroupUrl, setImageGroupUrl] = useState("");
	const [contactInformation, setContactInformation] = useState("");
	const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

	const [user, loading, error] = useAuthState(firebaseAuth);

	const createPostInFirestore = async () => {
		if (!user) return alert("You must be signed in to post.");

		// Perform validation
		if (!title) return alert("Please enter a title.");
		if (!description) return alert("Please enter a description.");
		if (!price) return alert("Please enter a price.");

		// Ensure that the user has selected at least one category
		if (selectedCategories.length === 0)
			return alert("Please select at least one category.");

		// Ensure that description is greater than 5 words
		const descriptionWords = description.split(" ");
		if (descriptionWords.length < 5)
			return alert("Please enter a description with at least 5 words.");

		const postDocument: StoredRequest = {
			title,
			description,
			price,
			userUid: user.uid,
			contactInformation,
			categories: selectedCategories,
		};

		// Create post in Firestore
		try {
			await addDoc(collection(db, "requests"), postDocument);

			onDismiss("testValue", "confirm");

			toast.success("Successfully posted request!");
		} catch (error) {
			console.error("Error adding document: ", error);
		}
	};
	return (
		<>
			<IonContent className="ion-padding">
				<Stack style={{ background: "white" }}>
					<TextInput
						withAsterisk
						size="md"
						label="Title"
						placeholder="Enter title"
						value={title}
						onChange={(event) => setTitle(event.currentTarget.value)}
					/>
					<Textarea
						size="md"
						placeholder="Write 5+ words"
						label="Description"
						value={description}
						onChange={(event) => setDescription(event.currentTarget.value)}
						withAsterisk
					/>

					<MultiSelect
						label="Categories"
						size="md"
						withAsterisk
						placeholder="Select at least 1 category"
						data={categories.map((category) => ({
							label: category.emoji + " " + category.name,
							value: category.name,
						}))}
						value={selectedCategories}
						onChange={(value) => setSelectedCategories(value)}
					/>

					<NumberInput
						placeholder="Price in USD"
						label="Target Price"
						size="md"
						withAsterisk
						min={0}
						value={price}
						onChange={(value) => setPrice(value)}
						icon={<CurrencyDollar size={18} />}
					/>

					<Textarea
						size="md"
						placeholder="Phone Number (preferred) or Instagram, etc."
						label="Contact Information (not shown to public) "
						value={contactInformation}
						onChange={(event) =>
							setContactInformation(event.currentTarget.value)
						}
						withAsterisk
					/>

					{loading ? (
						<Loader />
					) : (
						<IonButton
							color="secondary"
							fill="solid"
							onClick={createPostInFirestore}
						>
							Post
						</IonButton>
					)}
				</Stack>
			</IonContent>
		</>
	);
}
