import { Badge, Button, Container, Group, Title } from "@mantine/core";
import { collection, limit, orderBy, query } from "firebase/firestore";
import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { QuestionMark } from "tabler-icons-react";
import { db } from "../utilities/firebase";
import { useIonRouter } from "@ionic/react";

export default function RequestsDisplay() {
	const [value, loading, error] = useCollection(
		query(collection(db, "requests"), limit(5))
	);
	const router = useIonRouter();

	const formattedValues = value?.docs?.map((doc) => {
		const docData = doc.data() as any;

		return {
			category: docData.categories[0],
			description: docData.description,
			link: `/requests/${doc.id}`,
			price: `$${docData.price}`,
			title: docData.title,
		};
	});

	return (
		<Container size="xl" style={{ width: "100%" }}>
			<Title mb={6}>Requests</Title>

			<Group spacing={8}>
				{formattedValues?.map((value) => (
					<Button
						variant="light"
						color="blue"
						size="md"
						onClick={() => router.push(value.link)}
					>
						{value.title} | {value.price}
					</Button>
				))}
			</Group>
		</Container>
	);
}
