import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// STANFORD
// const firebaseConfig = {
//   apiKey: "AIzaSyAtvrlaJBc0XfCltLlt66UvPbcwHz5zcvQ",
//   authDomain: "strive-3bdb3.firebaseapp.com",
//   projectId: "strive-3bdb3",
//   storageBucket: "strive-3bdb3.appspot.com",
//   messagingSenderId: "679502809156",
//   appId: "1:679502809156:web:aee5fb10e8426ca41a9411",
//   measurementId: "G-JFEPQLFPNQ",
// };

// YALE
const firebaseConfig = {
	apiKey: "AIzaSyA30RyFJ9UwAPqs5oHJOWyreXbyt8k9x6k",
	authDomain: "yale-market-44464.firebaseapp.com",
	projectId: "yale-market-44464",
	storageBucket: "yale-market-44464.appspot.com",
	messagingSenderId: "494207114064",
	appId: "1:494207114064:web:43dec5857f8bcb75456a55",
	measurementId: "G-HJFZLH7XLR",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
