import { Button, Checkbox, Group, Popover, Stack, Text } from "@mantine/core";
import { useRefinementList } from "react-instantsearch-hooks-web";

export default function CustomRefinementList(props: any) {
  const {
    items,
    hasExhaustiveItems,
    createURL,
    refine,
    sendEvent,
    searchForItems,
    isFromSearch,
    canRefine,
    canToggleShowMore,
    isShowingMore,
    toggleShowMore,
  } = useRefinementList(props);

  // String that shows what categories are selected
  const selectedCategories = items
    .filter((item: any) => item.isRefined)
    .map((item: any) => item.label)
    .join(", ");

  // Count of all selected categories
  const selectedCategoriesCount = items.filter(
    (item: any) => item.isRefined
  ).length;

  return (
    <Popover width={400} position="bottom" withArrow shadow="md">
      <Popover.Target>
        <Button variant="outline" style={{ borderRadius: 50 }}>
          Categories{" "}
          {selectedCategoriesCount !== 0 && `(${selectedCategoriesCount})`}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <div>
          {items.map((item: any) => (
            <Stack>
              <Group>
                <Checkbox
                  size="lg"
                  key={item.label}
                  label={item.label}
                  checked={item.isRefined}
                  onChange={() => {
                    sendEvent("click", item.value);
                    refine(item.value);
                  }}
                />
                {/* Item count */}
                <span>{item.count}</span>
              </Group>
            </Stack>
          ))}
          {canToggleShowMore && (
            <button onClick={toggleShowMore}>
              {isShowingMore ? "Show less" : "Show more"}
            </button>
          )}
        </div>
        <Text size="sm">Select the categories that you'd like to see</Text>
      </Popover.Dropdown>
    </Popover>
  );
}
