import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	useIonRouter,
} from "@ionic/react";
import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useParams } from "react-router";
import { collection, doc, query, where } from "firebase/firestore";
import { db, firebaseAuth } from "../utilities/firebase";
import { ItemDetails } from "../components/ItemDetails";
import {
	StoredItem,
	transformUploadcareGroupUrl,
} from "../components/ItemsForYou";
import {
	Container,
	Group,
	Button,
	Skeleton,
	Loader,
	Title,
	Stack,
	SimpleGrid,
} from "@mantine/core";
import ToggleDarkMode from "../components/ToggleDarkMode";
import { Text } from "@mantine/core";
import { ItemCard } from "../components/ItemCard";
import FloatingPostButton from "../components/FloatingPostButton";
import { useAuthState } from "react-firebase-hooks/auth";
import PostButton from "../components/PostButton";

const Tab2 = ({ presentSignInModal }: { presentSignInModal: Function }) => {
	const router = useIonRouter();

	const [user, loadingUser] = useAuthState(firebaseAuth);

	// Fetch all items with the category in the categories property
	const itemsCollectionRef = collection(db, "items");
	const q = query(itemsCollectionRef, where("userUid", "==", user?.uid || ""));
	const [value, loading, error] = useCollection(q);

	// , where("categories", "array-contains", category

	const formattedValues = value?.docs?.map((doc) => {
		const docData = doc.data() as StoredItem;

		return {
			category: docData.categories[0],
			description: docData.description,
			image: transformUploadcareGroupUrl(docData.imageGroupUrl)[0],
			link: `/items/${doc.id}`,
			price: `$${docData.price}`,
			title: docData.title,
		};
	});

	return (
		<>
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<Group>
							<IonTitle>
								<Text size="xl" weight={700}>
									market @{" "}
									<Text color="blue" component="span">
										yale
									</Text>
								</Text>
							</IonTitle>
							<ToggleDarkMode />
						</Group>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<Container size="xl" style={{ width: "100%", height: "100%" }}>
						{!user && !loadingUser ? (
							<Stack mt="md">
								<Title>
									Join market@yale to start selling to the Yale community
								</Title>

								<Text>
									Sign up or sign in with your Yale email address to get
									started.
								</Text>
								<Button
									size="md"
									onClick={() => {
										presentSignInModal();
									}}
								>
									Sign in/up with Yale
								</Button>
							</Stack>
						) : (
							<>
								{" "}
								<Title mb={6} style={{ marginTop: "16px" }}>
									Your Items
								</Title>
								{formattedValues?.length === 0 && (
									<Text size="xl" weight={700}>
										You have not posted any items
									</Text>
								)}
								{loading ? (
									<Loader />
								) : (
									<SimpleGrid
										spacing={12}
										cols={2}
										breakpoints={[
											{ minWidth: "sm", cols: 2 },
											{ minWidth: "md", cols: 3 },
											{ minWidth: 1200, cols: 4 },
										]}
									>
										{formattedValues?.map((item) => (
											<ItemCard {...item} />
										))}
									</SimpleGrid>
								)}
							</>
						)}
						<PostButton presentSignInModal={presentSignInModal} />
					</Container>
				</IonContent>
			</IonPage>
		</>
	);
};

export default Tab2;
