import {
	IonButtons,
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import { Container, Title, Group, Stack, Text } from "@mantine/core";
import ExploreContainer from "../components/ExploreContainer";
import "./Tab1.css";
import ToggleDarkMode from "../components/ToggleDarkMode";
import FeaturedCards from "../components/FeaturedCards";
import ExploreItems from "../components/ExploreItems";
import PageGradient from "../components/PageGradient";
import FloatingPostButton from "../components/FloatingPostButton";
import ItemsForYou from "../components/ItemsForYou";
import { IonSearchbar, useIonRouter } from "@ionic/react";
import RequestsDisplay from "../components/RequestsDisplay";
import PostButton from "../components/PostButton";

const Tab1 = ({ presentSignInModal }: { presentSignInModal: Function }) => {
	const router = useIonRouter();

	return (
		<IonPage>
			<IonHeader translucent>
				<IonToolbar>
					<IonButtons slot="start">
						<ToggleDarkMode />
					</IonButtons>
					<IonTitle>
						<Text size="xl" weight={700}>
							market @{" "}
							<Text color="blue" component="span">
								yale
							</Text>
						</Text>
					</IonTitle>

					<IonButtons slot="end">
						<PostButton presentSignInModal={presentSignInModal} />
					</IonButtons>
				</IonToolbar>
				<IonToolbar>
					<IonSearchbar onClick={() => router.push("/search")} />
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<FloatingPostButton presentSignInModal={presentSignInModal} />
				<PageGradient />
				<Stack
					style={{ marginTop: "20px", backgroundColor: "gray.800" }}
					spacing={20}
				>
					<RequestsDisplay />
					{/* <FeaturedCards /> */}
					<ExploreItems />
					<ItemsForYou />
				</Stack>
			</IonContent>
		</IonPage>
	);
};

export default Tab1;
