import { IconEye, IconMessageCircle } from "@tabler/icons";
import { Card, Text, Group, Center, createStyles, Badge } from "@mantine/core";
import { useIonRouter } from "@ionic/react";
import { categories } from "../config/categories";

const useStyles = createStyles((theme, _params, getRef) => {
  const image = getRef("image");

  return {
    card: {
      position: "relative",
      height: 200,
      width: "100%",
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],

      [`&:hover .${image}`]: {
        transform: "scale(1.03)",
      },
    },

    image: {
      ref: image,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundSize: "cover",
      transition: "transform 500ms ease",
    },

    overlay: {
      position: "absolute",
      top: "20%",
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage:
        "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .85) 90%)",
    },

    content: {
      height: "100%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      zIndex: 1,
    },

    title: {
      color: theme.white,
      marginBottom: 5,
    },

    price: {
      color: theme.white,
      marginBottom: 5,
    },

    bodyText: {
      color: theme.colors.dark[2],
      marginLeft: 7,
    },

    author: {
      color: theme.colors.dark[2],
    },
  };
});

interface ImageCardProps {
  link: string;
  image: string;
  title: string;
  price: string;
  category: string;
}

export function ItemCard({
  image,
  title,
  price,
  link,
  category,
}: ImageCardProps) {
  const { classes, theme } = useStyles();

  const router = useIonRouter();

  // Find matching category object
  const categoryObject = categories.find((cat) => cat.name === category);

  return (
    <Card
      p="lg"
      shadow="lg"
      className={classes.card}
      radius="md"
      component="a"
      onClick={() => {
        router.push(link);
      }}
      target="_blank"
    >
      <div
        className={classes.image}
        style={{ backgroundImage: `url(${image}-/smart_resize/300x300/)` }}
      />
      <div className={classes.overlay} />

      <div className={classes.content}>
        <div>
          <Text size="lg" className={classes.title} weight={500}>
            {title}
          </Text>

          <Group position="apart" spacing="xs">
            <Badge
              variant="gradient"
              style={{
                background: categoryObject?.color.radialGradient,
              }}
            >
              {category}
            </Badge>

            <Group spacing="lg">
              <Center>
                <Text size="lg" className={classes.price} weight={800}>
                  {price}
                </Text>
              </Center>
            </Group>
          </Group>
        </div>
      </div>
    </Card>
  );
}
