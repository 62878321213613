const categoryColorMappings: {
	[char: string]: { radialGradient: string; color: string };
} = {
	lightBlue: {
		radialGradient:
			"radial-gradient(circle at 98% 97%, rgb(24, 147, 233), rgb(5, 181, 222))",
		color: "rgb(19, 155, 230)",
	},
	red: {
		radialGradient:
			"radial-gradient(circle at 98% 97%, rgb(245, 43, 79), rgb(243, 116, 38))",
		color: "rgb(245, 61, 69)",
	},
	pink: {
		radialGradient:
			"radial-gradient(circle at 98% 97%, rgb(210, 44, 159), rgb(244, 96, 149))",
		color: "rgb(218, 56, 157)",
	},
	purple: {
		radialGradient:
			"radial-gradient(circle at 98% 97%, rgb(128, 44, 210), rgb(163, 96, 244))",
		color: "rgb(136, 56, 218)",
	},

	blue: {
		radialGradient:
			"radial-gradient(circle at 98% 97%, rgb(44, 44, 210), rgb(96, 96, 244))",
		color: "rgb(56, 56, 218)",
	},
	green: {
		radialGradient:
			"radial-gradient(circle at 98% 97%, rgb(44, 210, 44), rgb(96, 244, 96))",
		color: "rgb(56, 218, 56)",
	},
	yellow: {
		radialGradient:
			"radial-gradient(circle at 98% 97%, rgb(210, 210, 44), rgb(244, 244, 96))",
		color: "rgb(218, 218, 56)",
	},
	orange: {
		radialGradient:
			"radial-gradient(circle at 98% 97%, rgb(210, 128, 44), rgb(244, 163, 96))",
		color: "rgb(218, 136, 56)",
	},
};

export const categories = [
	{
		name: "Dorm",
		color: categoryColorMappings.lightBlue,
		emoji: "🏠",
	},
	{
		name: "Electronics",
		color: categoryColorMappings.red,
		emoji: "📱",
	},
	{
		name: "Furniture",
		color: categoryColorMappings.pink,
		emoji: "🛋️",
	},
	{
		name: "Clothing",
		color: categoryColorMappings.orange,
		emoji: "👕",
	},
	{
		name: "Books",
		color: categoryColorMappings.green,
		emoji: "📚",
	},
	{
		name: "Misc",
		color: categoryColorMappings.purple,
		emoji: "📚",
	},
];
