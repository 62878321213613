import { Select } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { db, firebaseAuth } from "../utilities/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import toast from "react-hot-toast";

const DORMS = {
	Stiles: [
		"Entryway A",
		"Entryway B",
		"Entryway C",
		"Entryway D",
		"Entryway E",
		"Entryway F",
		"Entryway G",
		"Entryway H",
	],
	Morse: [
		"Entryway A",
		"Entryway B",
		"Entryway C",
		"Entryway D",
		"Entryway E",
		"Entryway F",
		"Entryway G",
		"Entryway H",
	],
	Saybrook: [
		"Entryway A",
		"Entryway B",
		"Entryway C",
		"Entryway D",
		"Entryway E",
		"Entryway F",
		"Entryway G",
		"Entryway H",
	],
	Silliman: [
		"Entryway A",
		"Entryway B",
		"Entryway C",
		"Entryway D",
		"Entryway E",
		"Entryway F",
		"Entryway G",
		"Entryway H",
	],
	TD: [
		"Entryway A",
		"Entryway B",
		"Entryway C",
		"Entryway D",
		"Entryway E",
		"Entryway F",
		"Entryway G",
		"Entryway H",
	],
	Murray: [
		"Entryway A",
		"Entryway B",
		"Entryway C",
		"Entryway D",
		"Entryway E",
		"Entryway F",
		"Entryway G",
		"Entryway H",
	],
	Franklin: [
		"Entryway A",
		"Entryway B",
		"Entryway C",
		"Entryway D",
		"Entryway E",
		"Entryway F",
		"Entryway G",
		"Entryway H",
	],
	Hopper: [
		"Entryway A",
		"Entryway B",
		"Entryway C",
		"Entryway D",
		"Entryway E",
		"Entryway F",
		"Entryway G",
		"Entryway H",
	],
	Berkeley: [
		"Entryway A",
		"Entryway B",
		"Entryway C",
		"Entryway D",
		"Entryway E",
		"Entryway F",
		"Entryway G",
		"Entryway H",
	],
	Trumbull: [
		"Entryway A",
		"Entryway B",
		"Entryway C",
		"Entryway D",
		"Entryway E",
		"Entryway F",
		"Entryway G",
		"Entryway H",
	],
	JE: [
		"Entryway A",
		"Entryway B",
		"Entryway C",
		"Entryway D",
		"Entryway E",
		"Entryway F",
		"Entryway G",
		"Entryway H",
	],
	Davenport: [
		"Entryway A",
		"Entryway B",
		"Entryway C",
		"Entryway D",
		"Entryway E",
		"Entryway F",
		"Entryway G",
		"Entryway H",
	],
	Pierson: [
		"Entryway A",
		"Entryway B",
		"Entryway C",
		"Entryway D",
		"Entryway E",
		"Entryway F",
		"Entryway G",
		"Entryway H",
	],
	"Old Campus": [
		"Lawrance",
		"Durfee",
		"LDub",
		"Welch",
		"Bingham",
		"Vandy",
		"Farnam",
	],
};

export default function DormSelector() {
	// Select resco first
	const [resco, setResco] = useState<string>();
	const [dorm, setDorm] = useState<string>();

	const [user, loading] = useAuthState(firebaseAuth);

	// Get dorm from profile if it exists
	useEffect(() => {
		if (!loading && user) {
			const docRef = doc(db, "users", user.uid);
			getDoc(docRef).then((doc) => {
				if (doc.exists()) {
					const data = doc.data();
					if (data?.resco) {
						setResco(data.resco);
					}
					if (data?.dorm) {
						setDorm(data.dorm);
					}
				}
			});
		}
	}, [loading, user]);

	// Handle Firestore update on user profile whenever dorm is updated
	useEffect(() => {
		if (!loading && user && dorm) {
			const docRef = doc(db, "users", user.uid);
			setDoc(
				docRef,
				{
					dorm,
					resco,
				},
				{ merge: true }
			).then(() => {
				toast.success("Updated dorm!");
			});
		}
	}, [dorm, loading, user]);

	return (
		<>
			<Select
				label="Resco"
				placeholder="Select resco"
				value={resco}
				onChange={(value) => {
					setResco((value || "Resco S") as keyof typeof DORMS);
				}}
				data={Object.keys(DORMS).map((resco) => ({
					label: resco,
					value: resco,
				}))}
			/>
			<Select
				label="Dorm"
				placeholder="Select dorm"
				value={dorm}
				onChange={(value) => {
					setDorm(value || "");
				}}
				data={
					resco
						? DORMS[resco as keyof typeof DORMS].map((dorm) => ({
								label: dorm,
								value: dorm,
						  }))
						: []
				}
			/>
		</>
	);
}
