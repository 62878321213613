import "./UploadImages.module.css";
import React, { useState } from "react";
import { Widget } from "@uploadcare/react-widget";

export default function UploadImages({
  setImageGroupUrl,
}: {
  setImageGroupUrl: (setImageGroupUrl: string) => void;
}) {
  return (
    <div>
      <Widget
        localeTranslations={{
          buttons: {
            choose: {
              images: {
                other: "Upload",
              },
            },
          },
        }}
        publicKey="6fd681a287c3af80b5e0"
        multiple
        imagesOnly
        systemDialog
        onChange={(info) => {
          if (!info.cdnUrl) {
            return;
          }

          setImageGroupUrl(info.cdnUrl);
        }}
      />
    </div>
  );
}
