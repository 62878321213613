import algoliasearch from "algoliasearch/lite";
import { SearchItemCard } from "./SearchItemCard";
import {
	InstantSearch,
	SearchBox,
	Highlight,
	Pagination,
	useHits,
	useSearchBox,
	useInfiniteHits,
} from "react-instantsearch-hooks-web";
import { Group, SimpleGrid } from "@mantine/core";
import { IonSearchbar } from "@ionic/react";
import SearchRangeSlider from "./SearchRangeSlider";
import CustomRefinementList from "./CustomRefinementList";
import { useEffect, useRef } from "react";
import NoHitsEmptyState from "./NoHitsEmptyState";

const searchClient = algoliasearch(
	"RPWQ8P7OKX",
	"69e7e0355ca7b72bddf2bcca0d004e88"
);

// STANFORD
// const searchClient = algoliasearch(
// 	"L8QICG0L1Z",
// 	"b85c507934b823ed28204a5fd9bf402d"
// );

function CustomHits() {
	const { hits, isLastPage, showMore } = useInfiniteHits();
	const sentinelRef = useRef(null);

	useEffect(() => {
		if (sentinelRef.current !== null) {
			const observer = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting && !isLastPage) {
						showMore();
						alert("attempting to show more");
					}
				});
			});

			observer.observe(sentinelRef.current);

			return () => {
				observer.disconnect();
			};
		}
	}, [isLastPage, showMore]);

	return (
		<>
			{hits.length === 0 && <NoHitsEmptyState />}

			<SimpleGrid
				spacing={12}
				cols={2}
				breakpoints={[
					{ minWidth: "sm", cols: 2 },
					{ minWidth: "md", cols: 3 },
					{ minWidth: 1200, cols: 4 },
				]}
			>
				{hits.map((hit: any) => (
					<SearchItemCard key={hit.title} hit={hit} />
				))}

				<div ref={sentinelRef} aria-hidden="true" />
			</SimpleGrid>
		</>
	);
}

function CustomSearchBox() {
	const { query, refine, clear } = useSearchBox();

	return (
		<IonSearchbar
			value={query}
			onIonChange={(e) => refine(e.detail.value!)}
			onIonClear={clear}
		/>
	);
}

export default function ItemDisplayWithSearch({
	defaultCategory,
}: {
	defaultCategory: string;
}) {
	return (
		<InstantSearch
			searchClient={searchClient}
			indexName="items"
			initialUiState={{
				items: {
					// Set default category
					refinementList: {
						categories: defaultCategory ? [defaultCategory] : [],
					},
				},
			}}
		>
			<CustomSearchBox />
			<Group spacing={6} my={8}>
				<SearchRangeSlider attribute="price" />
				<CustomRefinementList attribute="categories" />
			</Group>

			<CustomHits />
			{/* <Pagination /> */}
		</InstantSearch>
	);
}
