import React from "react";

import { Carousel } from "@mantine/carousel";

import { AspectRatio, Box, Image } from "@mantine/core";

export default function ImageGallery({ imageUrls }: { imageUrls: string[] }) {
  if (!imageUrls || imageUrls.length === 0) {
    return null;
  }

  return (
    <Carousel style={{ maxHeight: "60vh" }} loop mx="auto" withIndicators>
      {imageUrls.map((imageUrl) => (
        <Carousel.Slide style={{ backgroundColor: "gray" }}>
          <AspectRatio ratio={4 / 3}>
            <Image src={imageUrl} alt="image" />
          </AspectRatio>
        </Carousel.Slide>
      ))}
    </Carousel>
  );
}
